import React, { useEffect, useState } from "react";
import InputRadio from "../../../../components/InputRadio";
import { getIndexFromObjectArray } from "../../../../helpers/helpers";
import { createOptions } from "../../../../helpers/selects";
import { getUser } from "../../../../services/auth";

const MultiplePercentage = ({param, productParams, setProductParams}) => {
    const user = getUser();
    const [index, setIndex] = useState(null);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        let toIndex = getIndexFromObjectArray(productParams, 'id', param.id);
        console.log('options', param.options)
        setIndex(toIndex);
    }, [productParams]);

    useEffect(() => {
        getOptions();
    }, [param])

    const getOptions = () => {
        let toOptions = param.options.filter(option => option.admin_ids.length === 0 ? true : option.admin_ids.includes(user.id));
        setOptions(toOptions);
    }

    const handleParamChange = value => {
        setProductParams(prev => {
            let toProductParams = [...prev];
            toProductParams[index].value = value;
            return toProductParams;
        });
    }

    return (
        <>
            {index !== null &&
                <>
                    <div className="modal-section-title">
                        <div className="left">
                            <h2>{param.name}</h2>
                        </div>
                    </div>
                    <div className="section">
                        <InputRadio 
                            options={createOptions(options, 'description', 'value')}
                            value={productParams[index].value}
                            change={handleParamChange}
                        />
                    </div>
                </>
            }
        </>
    );
}

export default MultiplePercentage;