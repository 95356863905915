import React, { useState, useEffect } from "react";

import InputSelect from "../../../components/InputSelect";
import Input from "../../../components/Input";
import InputColors from "../../../components/InputColors";
import api from "../../../services/api";
import { createOptions } from "../../../helpers/selects";
import { convertIntToMoney } from "../../../helpers/conversions";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";

export const SubproductsTab = ({product, sku, subproducts, setSubproducts}) => {
    const [gettingColorGroups, setGettingColorGroups] = useState(true);

    const [colorGroups, setColorGroups] = useState([]);
    const [colors, setColors] = useState([]);
    const [subproductToChooseImage, setSubproductToChooseImage] = useState(null);
    const [subproductToChoosePrices, setSubproductToChoosePrices] = useState(null);

    const [selectedColors, setSelectedColors] = useState([]);

    useEffect(() => {
        getColorGroups();
    }, []);
    
    useEffect(() => {
        if(product){
            getSelectedColors();
        }
    }, [product]);

    useEffect(() => {
        handleChangeSelectedColors();
    }, [selectedColors]);

    const getColorGroups = () => {
        setGettingColorGroups(true);
        api.get(`/group-color`).then(res => {
            setColorGroups(res.data.resources);
            getColors(res.data.resources);
            setGettingColorGroups(false);
        });
    }

    const getColors = groups => {
        let toColors = [];
        groups.map(group => {
            group.colors.map(color => toColors.push(color));
        });
        setColors(toColors);
    }

    const handleStockChange = (value, index) => {
        const toSubproducts = [...subproducts];
        toSubproducts[index].stock = value;
        setSubproducts(toSubproducts);
    }

    const handleImageChange = image => {
        setSubproducts(prevSubproducts => {
            let toSubproducts = [...prevSubproducts];
            let subproduct = toSubproducts[subproductToChooseImage];
            if (subproduct.product_image_id !== image.id) {
                subproduct.product_image_id = image.id;
                subproduct.image = image;
            } else {
                subproduct.product_image_id = null;
                subproduct.image = null;
            }
            return toSubproducts;
        });
    }

    const handleChangeSelectedColors = () => {
        const toSubproducts = selectedColors.reduce((acc, selectedColor) => {
            const subproduct = product.subproducts.find(subproduct => subproduct.color_id === selectedColor);
            if (subproduct) {
                console.log(subproduct);
                acc.push(subproduct);
            } else {
                acc.push({
                    id: null,
                    product_id: product.id,
                    product_image_id: null,
                    color_id: selectedColor,
                    sku: `${product.sku}-${colors.find(color => color.id === selectedColor).sku}`,
                    status: true,
                    image: null,
                    stock: 0,
                    prices: []
                })
            }
            return acc;
        }, []);

        setSubproducts(toSubproducts);
    }

    const getSelectedColors = () => {
        let toSelectedColors = [];

        subproducts.map(subproduct => {
            if(!toSelectedColors.includes(subproduct.color_id)){
                toSelectedColors.push(subproduct.color_id);
            }
        });

        setSelectedColors(toSelectedColors);
    }

    const addPrice = () => {
        setSubproducts(prevSubproducts => {
            const updatedSubproducts = [...prevSubproducts];
            updatedSubproducts[subproductToChoosePrices] = {
                ...updatedSubproducts[subproductToChoosePrices],
                prices: [
                    ...updatedSubproducts[subproductToChoosePrices].prices,
                    {
                        init_quantity: '',
                        final_quantity: '',
                        formatted_price: 'R$ 0,00'
                    }
                ]
            };
            return updatedSubproducts;
        });
    };

    const handleChangeParam = (priceIndex, param, value) => {
        setSubproducts(prevSubproducts => {
            const updatedSubproducts = [...prevSubproducts];
            updatedSubproducts[subproductToChoosePrices] = {
                ...updatedSubproducts[subproductToChoosePrices],
                prices: updatedSubproducts[subproductToChoosePrices].prices.map((price, index) => {
                    if (index === priceIndex) {
                        return {
                            ...price,
                            [param]: value
                        };
                    }
                    return price;
                })
            };
            return updatedSubproducts;
        });
    };

    
    const deletePrice = priceIndex => {
        setSubproducts(prevSubproducts => {
            const updatedSubproducts = [...prevSubproducts];
            updatedSubproducts[subproductToChoosePrices] = {
                ...updatedSubproducts[subproductToChoosePrices],
                prices: updatedSubproducts[subproductToChoosePrices].prices.filter((_, index) => index !== priceIndex)
            };
            return updatedSubproducts;
        });
    };
    
    return (
        <>
            {subproductToChooseImage !== null &&
                <Modal
                    title={`Escolher imagem`}
                    show={true}
                    close={() => setSubproductToChooseImage(null)}
                >
                    <div className="section subproduct-images">
                        {product.images.map(image => (
                            <button 
                                onClick={() => handleImageChange(image)} 
                                className={subproducts[subproductToChooseImage].product_image_id === image.id ? 'active' : ''}
                            >
                                <img src={image.image_url.thumbnail} />
                            </button>
                        ))}
                    </div>
                </Modal>
            }
            {subproductToChoosePrices !== null &&
                <Modal
                    title={`Tabela de preços`}
                    show={true}
                    close={() => setSubproductToChoosePrices(null)}
                >
                    <div>

                    {subproducts[subproductToChoosePrices].prices.map((price, index) => (
                        <div className="section" key={index}>
                            <div className="row">
                                <div className="col">
                                    <Input label={`Quant. inicial`} mask={`convertToInt`} value={price.init_quantity} change={value => handleChangeParam(index, 'init_quantity', value)} disabled={product.origin.includes('integracao')} />
                                </div>
                                <div className="col">
                                    <Input label={`Quant. final`} mask={`convertToInt`} value={price.final_quantity} change={value => handleChangeParam(index, 'final_quantity', value)} disabled={product.origin.includes('integracao')} />
                                </div>
                                <div className="col">
                                    <Input label={`Preço`} value={price.formatted_price} mask={`convertToMoney`} change={value => handleChangeParam(index, 'formatted_price', value)} disabled={product.origin.includes('integracao')} />
                                </div>
                                {!product.origin.includes('integracao') &&
                                    <div className="col-1 col-customization-delete">
                                        <Button type={`delete`} svg={`delete-small`} action={() => deletePrice(index)} />
                                    </div>
                                }
                            </div>
                        </div>
                    ))}

                    {!product.origin.includes('integracao') &&
                        <Button 
                            type={`link`}
                            size={`small`}
                            text={`Cadastrar mais variações de preço`}
                            full={true}
                            action={addPrice}
                        />
                    }
                    </div>
                </Modal>
            }
            {gettingColorGroups &&
                <p>Carregando...</p>
            }
            {!gettingColorGroups && product &&
            <div>
                {product.origin.includes('integracao') &&
                    <div className="box-alert-info">
                        <p>Produtos atualizados via integração não podem ter as variações editadas.</p>
                    </div>
                }
                {!product.origin.includes('integracao') &&
                    <div className="row">
                        <div className="col-6">
                            <h2 className="section-title">Selecione as cores</h2>
                            <InputColors colorGroups={colorGroups} selecteds={selectedColors} setSelecteds={setSelectedColors} />
                        </div>
                    </div>
                }
                <h2 className="section-title">Detalhes das variações</h2>
                {subproducts.map((subproduct, index) => (            
                    <div key={index} className="row">
                        <div className="col-1">
                            <button 
                                className="btn-change-image"
                                onClick={() => setSubproductToChooseImage(index)}
                                disabled={product.origin.includes('integracao')}
                            >
                                {subproduct.image !== null &&
                                    <img src={subproduct.image.image_url.thumbnail} />
                                }
                                {subproduct.image === null &&
                                    <img src={ require(`../../../assets/images/svgs/image.svg`).default} />
                                }
                                {!product.origin.includes('integracao') &&
                                    <span>Escolher</span>
                                }
                            </button>
                        </div>
                        <div className="col">
                            <InputSelect label={`Cor da variação`}
                                options={createOptions(colors, 'name', 'id')}
                                value={subproduct.color_id}
                                isDisabled={true}
                                // change={value => handleColorChange(value, index)}
                            />
                        </div>
                        <div className="col">
                            <Input label={`SKU`} value={subproduct.sku} disabled={true} />
                        </div>
                        {product.origin.includes('integracao') &&
                            <div className="col">
                                <Input label={`SKU Fornecedor`} value={subproduct.company_sku} disabled={true} />
                            </div>
                        }
                        <div className="col">
                            <Input 
                                label={`Estoque`} 
                                value={subproduct.stock.toLocaleString('pt-BR')} 
                                mask={`convertToInt`} 
                                disabled={product.origin.includes('integracao')} 
                                change={value => handleStockChange(value, index)} 
                                right={<span className="small">unidade{subproduct.stock !== 1 ? 's' : ''}</span>}
                            />
                        </div>
                        <div className="col">
                            <Input 
                                label={`Preço a partir de`} 
                                value={subproduct.prices.length > 0 ? subproduct.prices[subproduct.prices.length - 1].formatted_price : 'R$ 0,00'} disabled={true} 
                                right={<Button svg={`table`} type={`icon`} action={() => setSubproductToChoosePrices(index)}></Button>}
                            />
                            
                        </div>
                    </div>
                ))}
            </div>
            }
        </>
    );
}

export default SubproductsTab;