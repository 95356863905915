import React, { useState, useEffect} from "react";
import { toast } from "react-toastify";
import Button from "../../../components/Button";
import { ModalDelete } from "../../../components/ModalDelete";
import { getBase64Promise } from "../../../helpers/conversions";
import { renderError } from "../../../helpers/errors";
import api from "../../../services/api";
import {closestCenter, DndContext, PointerSensor, useSensor} from '@dnd-kit/core';
import {arrayMove, horizontalListSortingStrategy, SortableContext} from '@dnd-kit/sortable';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import { hasPermission } from "../../../services/auth";

const ProductImagesTab = ({product, setProduct}) => {
    const [loading, setLoading] = useState(false);
    const [uploadedImages, setUploadedImages] = useState([]);
    const [images, setImages] = useState(product.images);
    const [imageToDelete, setImageToDelete] = useState(null);

    const chooseImage = () => {
        var input = document.createElement('input');
        input.type = 'file';
        input.multiple = true;

        input.onchange = e => { 
            let toUploadedImages = [];
            let files = e.target.files;
            for (let i = 0; i < files.length; i++) {
                toUploadedImages.push(files.item(i));
            }
            setUploadedImages(toUploadedImages);
        }

        input.click();
    }

    useEffect(() => {
        if(uploadedImages.length === 0) return;

        let promises = [];
        uploadedImages.map((uploadedImage, index) => {
            promises.push(getBase64Promise(uploadedImage));
        });
        Promise.all(promises).then(bases64 => {
            uploadImages(bases64);
        });
    }, [uploadedImages]);    

    const uploadImages = bases64 => {
        api.post(`/product/image`, {
            product_id: product.id,
            images: bases64
        }).then(res => {
            const toImages = res.data.resource.images;
            setProduct(prevProduct => {
                return {
                    ...prevProduct,
                    images: toImages
                };
            });
            setImages(toImages);
        }).catch(error => {
            renderError(error);
        }).then(() => setUploadedImages([]));
    }

    const deleteImage = () => {
        setLoading(true);
        api.delete(`/product/image/${imageToDelete}`).then(res => {
            toast.success('Imagem deletada com sucesso');
            setImages(prev => prev.filter(image => image.id !== imageToDelete));
        }).catch(error => {
            renderError(error);
        }).then(() => {
            setImageToDelete(null);
            setLoading(false)
        });
    }

    const sensors = [useSensor(SmartPointerSensor)];

    const handleDragEnd = ({active, over}) => {
        let oldImages = [...images];
        const oldIndex = images.findIndex(image => image.id === active.id);
        const newIndex = images.findIndex(image => image.id === over.id);

        let newImages = arrayMove(images, oldIndex, newIndex);
        setImages(newImages);

        let dataToUpdate = {images: []};

        newImages.map((newImage, index) => {
            dataToUpdate.images.push({
                id: newImage.id,
                order: index
            })
        });

        
        const promiseApi = api.put(`/product/image/order`, dataToUpdate);

        toast.promise(
            promiseApi,
            {
            pending: 'Atualizando ordem das imagens',
            error: 'Erro ao atualizar a ordem'
            }
        )
    }

    return (
        <>
            <ModalDelete
                show={imageToDelete !== null}
                action={deleteImage}
                close={() => setImageToDelete(null)}
                loading={loading}
            />
            <div className="product-images">
                {hasPermission('edit_product') &&
                    <button onClick={() => chooseImage()}>
                        <img src={ require(`../../../assets/images/svgs/upload.svg`).default} />
                        <span>Enviar imagem</span>
                        <span><small>1.200px por 1.200px</small></span>
                    </button>
                }

                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                >
                    <SortableContext 
                        items={images.map(image => image.id)}
                        strategy={horizontalListSortingStrategy}
                    >
                        {images.map(image => (
                            <SortableItem key={image.id} image={image} setImageToDelete={setImageToDelete} />
                        ))}
                    </SortableContext>
                </DndContext>

                {uploadedImages.map((image, index) => (
                    <div key={index}>
                        <img key={index} src={require(`../../../assets/images/svgs/loading.svg`).default } />
                    </div>
                ))}
            </div>
        </>
    );
}

function SortableItem(props) {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
    } = useSortable({id: props.image.id});
    
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      listStyle: 'none'
    };
    
    return (
      <li ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <div className="product-image">
            <img src={props.image.image_url.thumbnail} />
            {hasPermission('edit_product') &&
                <Button type={`transparent`} size={`small`} svg={`delete`} action={() => props.setImageToDelete(props.image.id)} />
            }
        </div>
      </li>
    );
}

export class SmartPointerSensor extends PointerSensor {
    static activators = [
        {
            eventName: "onPointerDown",
            handler: ({ nativeEvent: event }) => {
                if (
                    !event.isPrimary ||
                    event.button !== 0 ||
                    isInteractiveElement(event.target)
                ) {
                    return false;
                }

                return true;
            },
        },
    ];
}

function isInteractiveElement(element) {
    const interactiveElements = [
        "button",
    ];
    if (
        element?.tagName &&
        interactiveElements.includes(element.tagName.toLowerCase())
    ) {
        return true;
    }

    return !hasPermission('edit_product');
}

export default ProductImagesTab;