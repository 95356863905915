import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import PanelTemplate from "../../templates/Panel";
import PageFooter from "../../components/PageFooter";
import Button from "../../components/Button";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";

import { ModalDelete } from "../../components/ModalDelete";
import BoxTabs from "../../components/BoxTabs";
import OrderDetailTab from "./tabs/detail";
import OrderNfsTab from "./tabs/nfs";
import BankSlipTab from "./tabs/bankslips";
import { toast } from "react-toastify";
import Box from "../../components/Box";
import Table from "../../components/Table";
import { convertIntToMoney, convertMoenyToInt } from "../../helpers/conversions";
import ModalProductDetails from "./modals/ModalProductDetails";
import { hasPermission } from "../../services/auth";
import { checkComissao, countProductPendingConferences, countProductPendingConferencesTag } from "./helpers/conference";

export const OrderEdit = () => {
    const { id } = useParams();
    const navigete = useNavigate();

    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const [itemsData, setItemsData] = useState({header: [], rows: []});

    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedPagamentoStatus, setSelectedPagamentoStatus] = useState(null);
    const [selectedFaturadoStatus, setSelectedFaturadoStatus] = useState(null);
    const [financeComments, setFinanceComments] = useState('');

    const [shippingTypeReal, setShippingTypeReal] = useState(null);
    const [shippingFeeReal, setShippingFeeReal] = useState('R$ 0,00');
    const [shippingTimeReal, setShippingTimeReal] = useState('');
    const [paymentTermReal, setPaymentTermReal] = useState('');
    const [paymentFormReal, setPaymentFormReal] = useState('');
    const [billingCompanyCnpjReal, setBillingCompanyCnpjReal] = useState('');

    const [shippingTypeConference, setShippingTypeConference] = useState(false);
    const [shippingFeeConference, setShippingFeeConference] = useState(false);
    const [shippingTimeConference, setShippingTimeConference] = useState(false);
    const [paymentTermConference, setPaymentTermConference] = useState(false);
    const [paymentFormConference, setPaymentFormConference] = useState(false);
    const [billingCompanyCnpjConference, setBillingCompanyCnpjConference] = useState(false);

    const [itemToShowDetails, setItemToShowDetails] = useState(null)

    useEffect(() => {
        getOrder();
    }, []);

    const getOrder = () => {
        setLoading(true);

        api.get(`/order/${id}`).then(res => {
            let resource = res.data.resource;
            console.log('resource', resource);
            setOrder(resource);
            setSelectedStatus(resource.status_id);
            setSelectedPagamentoStatus(resource.pagamento_status);
            setSelectedFaturadoStatus(resource.faturado_status);
            setFinanceComments(resource.finance_comments);
            setShippingTypeReal(resource.shipping_type_real);
            setShippingTypeConference(resource.shipping_type_conference);
            setShippingFeeReal(convertIntToMoney(resource.shipping_fee_real, true));
            setShippingFeeConference(resource.shipping_fee_conference);
            setShippingTimeReal(resource.shipping_time_real);
            setShippingTimeConference(resource.shipping_time_conference);
            setPaymentTermReal(resource.payment_term_real);
            setPaymentTermConference(resource.payment_term_conference);
            setPaymentFormReal(resource.payment_form_real);
            setPaymentFormConference(resource.payment_form_conference);
            setBillingCompanyCnpjReal(resource.billing_company_cnpj_real);
            setBillingCompanyCnpjConference(resource.billing_company_cnpj_conference);
            getItems(resource.items);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const deleteOrder = () => {
        setLoading(true);

        api.delete(`/order/${id}`).then(res => {
            navigete(`/order/list`);
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const updateOrder = () => {
        setLoading(true);

        api.put(`/order/${order.id}`, {
            status_id: selectedStatus
        }).then(res => {
            toast.success('Pedido atualizado com sucesso');
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const getItems = items => {
        let header = ['Imagem', 'SKU', 'Nome', 'Quant.', 'Preço unit.', 'Total'];
        const rows = [];

        // if(hasPermission('view_margem_order')){
        //     header.push('Margem orçamento');
        //     header.push('Margem real');
        // }

        // header.push('Comissão');
        // header.push('Conferência');
        
        items.map(item => {

            let image = <img src={item.subproduct_image_url.small} />;

            let data = [
                image, 
                item.subproduct_sku,
                item.product_name,
                item.quantity, 
                `R$${convertIntToMoney(item.price)}`, 
                `R$${convertIntToMoney(item.price * item.quantity)}`
            ];

            // if(hasPermission('view_margem_order')){
            //     data.push(`R$${convertIntToMoney(item.margem_orcamento)}`);
            //     data.push(`R$${convertIntToMoney(item.margem_real)}`);
            // }
            
            // data.push(checkComissao(item));
            // data.push(countProductPendingConferencesTag(item));

            rows.push({
                data,
                // action: () => console.log(item)
                // action: () => setItemToShowDetails(item)
            });
        });

        setItemsData({header, rows});
    }

    const sendOrder = () => {
        setLoading(true);

        api.post(`/order/${order.id}/send`).then(res => {
            toast.success('Pedido enviado com sucesso');
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    return (
        <>
            {itemToShowDetails &&
                <ModalProductDetails
                    close={() => setItemToShowDetails(null)}
                    item={itemToShowDetails}
                    getOrder={getOrder}
                />
            }
            <ModalDelete
                show={confirmDelete}
                action={deleteOrder}
                loading={loading}
                close={() => setConfirmDelete(false)}
            />
            <PanelTemplate id="order-edit">
                <BoxTabs
                    titles={[
                        'Detalhes',
                        'Notas fiscais',
                        'Boletos'
                    ]}
                    contents={
                        [
                            <OrderDetailTab
                                order={order}
                                selectedStatus={selectedStatus}
                                setSelectedStatus={setSelectedStatus}
                                selectedPagamentoStatus={selectedPagamentoStatus}
                                setSelectedPagamentoStatus={setSelectedPagamentoStatus}
                                selectedFaturadoStatus={selectedFaturadoStatus}
                                setSelectedFaturadoStatus={setSelectedFaturadoStatus}
                                financeComments={financeComments}
                                setFinanceComments={setFinanceComments}
                                shippingTypeReal={shippingTypeReal}
                                setShippingTypeReal={setShippingTypeReal}
                                shippingFeeReal={shippingFeeReal}
                                setShippingFeeReal={setShippingFeeReal}
                                shippingTimeReal={shippingTimeReal}
                                setShippingTimeReal={setShippingTimeReal}
                                paymentTermReal={paymentTermReal}
                                setPaymentTermReal={setPaymentTermReal}
                                paymentFormReal={paymentFormReal}
                                setPaymentFormReal={setPaymentFormReal}
                                shippingTypeConference={shippingTypeConference}
                                setShippingTypeConference={setShippingTypeConference}
                                shippingFeeConference={shippingFeeConference}
                                setShippingFeeConference={setShippingFeeConference}
                                shippingTimeConference={shippingTimeConference}
                                setShippingTimeConference={setShippingTimeConference}
                                paymentTermConference={paymentTermConference}
                                setPaymentTermConference={setPaymentTermConference}
                                paymentFormConference={paymentFormConference}
                                setPaymentFormConference={setPaymentFormConference}
                                billingCompanyCnpjReal={billingCompanyCnpjReal}
                                setBillingCompanyCnpjReal={setBillingCompanyCnpjReal}
                                billingCompanyCnpjConference={billingCompanyCnpjConference}
                                setBillingCompanyCnpjConference={setBillingCompanyCnpjConference}
                            />,
                            <OrderNfsTab
                                order={order}
                            />,
                            <BankSlipTab
                                order={order}
                            />
                        ]
                    }
                />
                <Box
                    className={`no-padding`}
                    title={`Produtos do pedido`}
                >
                    <Table header={itemsData.header} rows={itemsData.rows} loading={loading} />
                </Box>
                <PageFooter spaceBetween={true}>
                    <div>
                        {hasPermission('edit_order') &&
                            <Button type={`primary`} size={`small`} svg={`save-white`} text={`Salvar alterações`} action={updateOrder} loading={loading} />
                        }
                        <Button type={`secondary`} size={`small`} svg={`pdf`} text={`Visualizar pedido`} action={() => window.open(order?.pdf_url)} loading={loading} />
                        {hasPermission('send_order') &&
                            <Button type={`secondary`} size={`small`} svg={`send`} text={`Enviar pedido`} action={sendOrder} loading={loading} />
                        }
                    </div>
                    <div>
                        {hasPermission('delete_order') &&
                            <Button type={`secondary`} size={`small`} loading={loading} text={`Excluir`} svg={`delete-small`} action={() => setConfirmDelete(true)} />
                        }
                    </div>
                </PageFooter>
            </PanelTemplate>
        </>
    );
}

export default OrderEdit;